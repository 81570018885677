import { navigate } from 'gatsby'
import React, { useContext, useEffect } from 'react'
import { connect } from 'react-redux'
import SelectorContainer from '../../../src/common/components/FunnelSelector/Entrada/SelectorContainer'
import SelectorForm from '../../../src/common/components/FunnelSelector/Entrada/SelectorForm'
import Step, {
  findNext,
  InSkipInfo,
} from '../../../src/common/components/FunnelSelector/SelectorSteps'
import ResponsiveImages from '../../../src/common/components/ResponsiveImages/ResponsiveImages'
import SearchNeumaticos from '../../../src/common/components/searchNeumaticosCardHome/index'
import ResponsiveSliderBanner from '../../../src/common/components/ResponsiveSliderBanner/ResponsiveSliderBanner'
import Title from '../../../src/common/components/Title'
import { reset } from '../../../src/common/context/actions/selectorActions'
import AppContext, { IAppState } from '../../../src/common/context/context'
import { selectSkipInfo } from '../../../src/common/context/selectors/selectorSelectors'
import { locale } from '../../../src/common/i18n'
import { temporadaMapping } from '../../../src/specific/helpers/gtmReporter'
import { getImages, ImageInputData } from '../../../src/common/utils/imagesS3'
import route from '../../../src/common/utils/route'
import { SeoData } from '../../../src/common/types/Seo'

const placeholderImage = {
  Key: '',
  url: 'https://via.placeholder.com/800x200?text=Placeholder+Image',
  link: '',
  alt: '',
}
interface InSeoData {
  seoData: SeoData
}
interface Props {
  styles: { [k: string]: string }
  data?: InSeoData | ImageInputData
  tablet?: boolean
  desktop?: boolean
}

const Landing4x4Ca = ({ styles, data }: Props) => {
  const banners_mobile = getImages(
    data[`banners_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const banners_desktop = getImages(
    data[`banners_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  const [image_mobile] = getImages(
    data[`images_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const [image_desktop] = getImages(
    data[`images_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  return (
    <div className={styles.container}>
      <Title text="Pneumàtics per 4x4" />
      <Selector styles={styles} />
      <article className={styles.description}>
        <p className={styles.main_description}>
          Els <strong>pneumàtics de 4x4</strong> disposen d'un disseny i unes
          prestacions especials que els permet tenir una adherència molt
          polivalent capaç d'adaptar-se a qualsevol tipus de terreny. Si el teu
          és l'aventura has de assegurar-te que el teu 4x4 està equipat amb{' '}
          <strong>les millors rodes</strong> per l'ús que en faràs. El format de
          roda del tot terreny durant els últims anys s'ha vist popularitzat a
          més per l'aparició dels cotxes SUV que també poden muntar aquesta
          classe de pneumàtic.
        </p>
        <ResponsiveSliderBanner
          desktopImages={banners_desktop}
          mobileImages={banners_mobile}
          sizesVariant={'banner_landings'}
        />
        <section className={styles.section}>
          <h2>¿Com escollir uns bons Pneumàtics per al meu cotxe 4x4?</h2>
          <div>
            <p>
              Has de tenir en compte que per a vehicles 4x4 ha diferents classes
              de roda. Depenent de l'ús que donaràs al cotxe, més o menys Off
              Road, hauràs de triar entre els següents tipus de roda per 4x4:
            </p>
            <ul>
              <li>
                <strong>Pneumàtics de carretera: </strong> Pensats per a
                realitzar un ús 80% per asfalt i un 20% per camp o Off Road. Són
                el model de pneumàtic més venut del segment i l'elecció més
                habitual per als vehicles tot terreny que circulen per ciutat de
                manera regular amb escapades ocasionals a la muntanya. Els nous
                cotxes tipus SUV acostumen a muntar majoritàriament aquesta
                classe de pneumàtic.
              </li>
              <li>
                <strong>Pneumàtics mixtos 4x4: </strong>Pensats per fer un ús
                50% per ciutat i un 50% camp o Off Road. Ofereixen un molt bon
                rendiment tant en ciutat com a camins de muntanya. Són el tipus
                de roda d'aquells que requereixen d'un cotxe que els permeti
                moure’s per l'asfalt amb tranquil·litat però que en el moment
                d'entrar a pista ofereixi un excel·lent adherència i facilitat
                de maneig en cas de trobar-se amb fang i pluja.
              </li>
              <li>
                <strong>Pneumàtics Off Road: </strong>Destinats especialment per
                a recórrer camins de camp en el 80% del seu ús. És habitual en
                cotxes que circulen per zones de terra o sorra a diari. La seva
                carcassa és més dura, els seus flancs estan reforçats i el seu
                dibuix està ben marcat per a què l'adherència sigui la màxima
                possible.
              </li>
            </ul>
          </div>
        </section>
        <ResponsiveImages
          imageDesktop={image_desktop}
          imageMobile={image_mobile}
        />
        <section className={styles.section}>
          <h2>
            Què he de tenir en compte per circular per zones de camp amb un tot
            terreny?
          </h2>
          <div>
            <p>
              El primer que has de fer és valorar el percentatge de temps de la
              teva conducció que vas a destinar a realitzar camins per poder
              triar quin format de roda 4x4 és millor per a tu. En terrenys Off
              Road també t'aconsellem:
            </p>
            <ul>
              <li>
                Desplaçar-te a una velocitat baixa. Als camins Off Road és fàcil
                trobar-se amb imprevistos, la velocitat moderada ajudarà a
                reaccionar a temps en cas de ser necessari.
              </li>
              <li>
                Mira de circular sense grans carregues. Com més gran es el pes
                del cotxe 4x4 més dificultat té per frenar a terra.
              </li>
              <li>
                Si trobes una zona amb molt de fang intenta no realitzar grans
                accelerades. Evitaràs la possibilitat de quedar-te encallat.
              </li>
            </ul>
            <p>
              Si necessites que t'ajudem amb els teus Pneumàtics Off Road demana
              cita en el teu <strong>taller Rodi</strong> més proper i els
              nostres tècnics especialistes resoldran tots els teus dubtes.
            </p>
          </div>
        </section>
      </article>
    </div>
  )
}

const Landing4x4Es = ({ styles, data }: Props) => {
  const banners_mobile = getImages(
    data[`banners_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const banners_desktop = getImages(
    data[`banners_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  const [image_mobile] = getImages(
    data[`images_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const [image_desktop] = getImages(
    data[`images_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )

  return (
    <div className={styles.container}>
      <Title text="Neumáticos para 4x4" />
      <Selector styles={styles} />
      <article className={styles.description}>
        <p className={styles.main_description}>
          Los <strong>neumáticos de 4x4</strong> disponen de un diseño y unas
          prestaciones especiales que les permite tener un agarre muy
          polivalente capaz de adaptarse a cualquier tipo de terreno. Si lo tuyo
          es la aventura tienes que asegurarte que tu 4x4 está equipado con{' '}
          <strong>las mejores ruedas</strong> para el uso que vas a darle. El
          formato de rueda del todoterreno durante los últimos años se ha visto
          popularizado además por la aparición de los{' '}
          <strong>
            coches SUV que también pueden montar esta clase de neumático.
          </strong>
        </p>
        <ResponsiveSliderBanner
          desktopImages={banners_desktop}
          mobileImages={banners_mobile}
          sizesVariant={'banner_landings'}
        />
        <section className={styles.section}>
          <h2>¿Cómo escoger unos buenos Neumáticos para mi coche 4x4?</h2>
          <div>
            <p>
              Tienes que tener en cuenta que para vehículos 4x4 existen
              diferentes clases de rueda. Dependiendo del uso que vas a dar al
              coche, más o menos Off-Road, tendrás que elegir entre los
              siguientes <strong>tipos de rueda para 4x4:</strong>
            </p>
            <ul>
              <li>
                <strong>Neumáticos de carretera: </strong> Pensados para
                realizar un uso 80% por asfalto y un 20% de campo o OffRoad. Son
                el modelo de neumático más vendido y la elección más habitual
                para los vehículos todoterreno que circulan por ciudad de forma
                regular con escapadas ocasionales a la montaña. Los nuevos{' '}
                <strong>coches tipo SUV</strong> acostumbran a montar en su
                mayoría esta clase de neumático.
              </li>
              <li>
                <strong>Neumáticos mixtos 4x4: </strong>Pensados para hacer un
                uso 50% por ciudad y un 50% campo o OffRoad. Ofrecen un muy buen
                rendimiento tanto en ciudad como en caminos de montaña. Son el
                tipo de rueda de aquellos que requieren de un coche que les
                permita moverse por el asfalto con tranquilidad pero que en el
                momento de entrar a pista ofrezca un excelente agarre y
                facilidad de manejo en caso de encontrarse con barro y lluvia.{' '}
              </li>
              <li>
                <strong>Neumáticas Off-Road: </strong>Destinados especialmente
                para recorrer caminos de campo en el 80% de su uso. Es habitual
                en coches que circulan por zonas de tierra o arena a diario. Su
                carcasa es más dura, sus flancos están reforzados y su dibujo
                está bien marcado para que el agarre sea el máximo posible.{' '}
              </li>
            </ul>
          </div>
        </section>
        <ResponsiveImages
          imageDesktop={image_desktop}
          imageMobile={image_mobile}
        />
        <section className={styles.section}>
          <h2>
            ¿Qué tengo que tener en cuenta para circular por zonas de campo con
            un todoterreno?
          </h2>
          <div>
            <p>
              Lo primero que debes hacer es valorar el porcentaje de tiempo de
              tu conducción que vas a destinar a realizar caminos para poder
              elegir qué formato de rueda 4x4 es mejor para ti. En terrenos Off
              Road también te aconsejamos:
            </p>
            <ul>
              <li>
                Desplazarte a una velocidad adecuada. En los caminos Off Road es
                fácil encontrarse con imprevistos, la velocidad moderada ayudará
                a reaccionar a tiempo en caso de ser necesario.
              </li>
              <li>
                Mira de circular sin grandes cargas. Cuanto mayor es el peso del
                coche 4x4 más dificultad tiene para frenar en tierra.{' '}
              </li>
              <li>
                Si encuentras una zona con mucho barro intenta no realizar
                grandes acelerones. Evitaras la posibilidad de quedarte
                atascado.{' '}
              </li>
            </ul>
            <p>
              Si necesitas que te ayudemos con tus Neumáticos Off-Road solicita
              cita en tu taller rodi más cercano y nuestros técnicos
              especialistas resolverán todas tus dudas.
            </p>
          </div>
        </section>
      </article>
    </div>
  )
}

interface SelectorProps {
  styles: { [k: string]: string }
  dispatch: (action: object) => void
  skipInfo: InSkipInfo
}
const ConnectedSelector = ({ dispatch, styles, skipInfo }: SelectorProps) => {
  const { selectorState } = useContext(AppContext)
  // Initialize selector values
  useEffect(() => {
    dispatch(
      reset({
        keepValues: false,
        marca: null,
        origen: route('landing-4x4'),
        temporada: {
          selectable: process.env.GATSBY_WEB === 'rodi',
          value: 'verano',
        },
        tipoVehiculo: 2,
      })
    )
  }, [])

  return (
    <React.Fragment>
      <SearchNeumaticos
        gtmData={{
          action: '4x4',
          runflat: selectorState.runflat.value,
          tipo_neumaticos: temporadaMapping[selectorState.temporada.value],
        }}
      />

      <div className={styles.selector_container}>
        <SelectorContainer showMarcas={false} origin={route('landing-4x4')}>
          {({ ...props }) => (
            <SelectorForm
              showTemporada={process.env.GATSBY_WEB === 'rodi'}
              fromModal={false}
              handleSubmit={() => {
                const next = findNext(Step.SELECT_MARCAS, 'desktop', skipInfo)
                navigate(next.routes.desktop)
              }}
              {...props}
            />
          )}
        </SelectorContainer>
      </div>
    </React.Fragment>
  )
}
const Selector = connect((state: IAppState) => ({
  skipInfo: selectSkipInfo(state),
}))(ConnectedSelector)

const Landing4x4 = ({ ...props }: Props) =>
  locale === 'es' ? <Landing4x4Es {...props} /> : <Landing4x4Ca {...props} />

export default Landing4x4
